import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "margin-dialog-body" } },
    [
      _c(
        VContainer,
        [
          _c(
            VCard,
            [
              _c(
                VSnackbar,
                {
                  attrs: {
                    timeout: -1,
                    value: true,
                    color: "primary",
                    center: "",
                    bottom: ""
                  },
                  model: {
                    value: _vm.snackbar,
                    callback: function($$v) {
                      _vm.snackbar = $$v
                    },
                    expression: "snackbar"
                  }
                },
                [
                  _c(
                    VCardText,
                    { staticClass: "font-weight-black" },
                    [
                      _vm._v(" Deseja fazer o download do template agora? "),
                      _c(
                        VBtn,
                        {
                          attrs: { text: "", small: "", href: _vm.link_modelo }
                        },
                        [_vm._v(" Clique aqui ")]
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "white black--text mx-3",
                          attrs: { "x-small": "", fab: "" },
                          on: { click: _vm.fechaSnack }
                        },
                        [_c(VIcon, [_vm._v("close")])],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCard,
            { staticClass: "pa-3" },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    {
                      attrs: { xs: "2", sm: "2", md: "2", lg: "2", cols: "12" }
                    },
                    [
                      _c(
                        VIcon,
                        {
                          staticClass: "grey--text mt-5 pt-5",
                          attrs: { size: "100" }
                        },
                        [_vm._v("mdi-file-upload")]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      attrs: { xs: "4", sm: "4", md: "5", lg: "5", cols: "12" }
                    },
                    [
                      _c(
                        VCardText,
                        {
                          staticClass:
                            "grey--text display-1 font-weight-black text-wrap"
                        },
                        [_vm._v(" Manual de Upload via Painel")]
                      ),
                      _c(
                        VCardText,
                        { staticClass: "font-weight-light text-wrap" },
                        [
                          _vm._v(
                            " A funcionalidade upload de títulos permite o envio de títulos para protesto para cartórios de protesto em todos os Estados do Brasil. Ela é disponibilizada logo após a homologação de um novo Apresentante perante os cartórios de protesto e envio das informações de acesso. Para tanto, o usuário faz a importação de planilha com os dados dos títulos completando o respectivo template padrão, cumprindo nossas instruções para realizar o seu upload na www.recuperi.com.br. "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    {
                      attrs: { xs: "4", sm: "4", md: "5", lg: "5", cols: "12" }
                    },
                    [
                      _c(VImg, {
                        staticClass: "margin-auto ma-3 pa-3",
                        attrs: { src: require("@/assets/planilha-upload.png") }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { md: "12" } },
            [
              _c(
                VCardText,
                { staticClass: "grey--text font-weight-black title text-wrap" },
                [_vm._v("Legenda de Cores da planilha:")]
              ),
              _c(VCardText, { staticClass: "font-weight-light" }, [
                _vm._v(
                  "Cada campo na planilha possui seu tipo de informação definido atraves das cores abaixo:"
                )
              ]),
              _c(
                VRow,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VCol,
                    { attrs: { md: "4" } },
                    [
                      _c(
                        VCard,
                        { staticClass: "grey lighten-4" },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VBtn,
                                { attrs: { fab: "", color: "success" } },
                                [
                                  _c(VIcon, { staticClass: "white--text" }, [
                                    _vm._v("account_circle")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardTitle,
                                { staticClass: "grey--text title" },
                                [_vm._v("Dados do Devedor")]
                              )
                            ],
                            1
                          ),
                          _c(
                            VCardText,
                            { staticClass: "font-weight-light" },
                            [
                              _vm._v(
                                "Dados referente ao Devedor, como Nome, Cidade, Endereço, etc."
                              )
                            ]
                          ),
                          _c(
                            VCardText,
                            { staticClass: "font-weight-light" },
                            [
                              _vm._v(
                                "Confira abaixo todos os campos listados e suas validações"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { attrs: { md: "4" } },
                    [
                      _c(
                        VCard,
                        { staticClass: "grey lighten-4" },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VBtn,
                                { attrs: { fab: "", color: "amber" } },
                                [
                                  _c(VIcon, { staticClass: "white--text" }, [
                                    _vm._v("account_circle")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardTitle,
                                { staticClass: "grey--text title" },
                                [_vm._v("Dados do Sacador")]
                              )
                            ],
                            1
                          ),
                          _c(
                            VCardText,
                            { staticClass: "font-weight-light" },
                            [
                              _vm._v(
                                "Dados referentes ao Sacador são gerados automaticamente pelo sistema"
                              )
                            ]
                          ),
                          _c(
                            VCardText,
                            { staticClass: "font-weight-light" },
                            [
                              _vm._v(
                                "Confira abaixo todos os campos listados e suas validações"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    VCol,
                    { attrs: { md: "4" } },
                    [
                      _c(
                        VCard,
                        { staticClass: "grey lighten-4" },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VBtn,
                                { attrs: { fab: "", color: "cyan darken-1" } },
                                [
                                  _c(VIcon, { staticClass: "white--text" }, [
                                    _vm._v("account_circle")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardTitle,
                                { staticClass: "grey--text title" },
                                [_vm._v("Parâmetros de Protesto")]
                              )
                            ],
                            1
                          ),
                          _c(
                            VCardText,
                            { staticClass: "font-weight-light" },
                            [
                              _vm._v(
                                "Dados de parâmetros são informações utilizadas pelo sistema para emitir o protesto"
                              )
                            ]
                          ),
                          _c(
                            VCardText,
                            { staticClass: "font-weight-light" },
                            [
                              _vm._v(
                                "Confira abaixo todos os campos listados e suas validações"
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { sm: "12", md: "12" } },
            [
              _c(
                VCardText,
                { staticClass: "grey--text font-weight-black title" },
                [_vm._v("Informações básicas de cada campo da planilha")]
              ),
              _c(VCardText, { staticClass: "font-weight-light" }, [
                _vm._v(
                  " Para facilitar o processo de upload, preparamos esse manual onde estão listados todos os campos do template: "
                )
              ]),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Nome do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Informe aqui o Nome do Devedor por extenso. Dica: limite máximo de 45 caracteres, sem símbolos, acento ou outros caracteres especiais "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    " Correto: Francisco Araujo de Cardoso "
                                  )
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "Uso Incorreto: Francisco A. de Cardoso. "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(" Campo Obrigatório. ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Endereço do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: limite máximo de 45 caracteres, sem símbolos, acento ou outros caracteres especiais "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "Uso Correto: Avenida Silva Jardim, 450, ap 100 *(se houver apartamento) "
                                  )
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "Uso Correto: Avenida Sete de Setembro, 2000 "
                                  )
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "Uso Incorreto: Avenida Silva Jardim, 450, Curitiba, Cep 81100-200 "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(" Campo Obrigatório ")
                              ]),
                              _c(VCardText, [
                                _vm._v(
                                  " Observação: As informações de Bairro, CEP, e Cidade não podem ser informadas nesse campo. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Bairro do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: limite máximo de 20 caracteres, sem símbolos, acento ou outros caracteres especiais "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Correto: Centro ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    "Uso Incorreto: Centro, Curitiba, Cep 81100-200 "
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" CEP do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hífens "
                                )
                              ]),
                              _c(VCardText, [
                                _vm._v(
                                  " Informar o CEP com 8 dígitos. Se tiver zeros à esquerda, é preciso informar. Exemplo: 01418001 "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Incorreto: 81110-200 ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Correto: 81110200 ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Incorreto: 81110.200 ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Cidade do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hífens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Correto: Curitiba ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Incorreto: Curitiba-PR ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" UF do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hífens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Correto: PR ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Incorreto: Paraná ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Tipo Documento Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: Especifica qual o tipo de documento a ser enviado no campo Tipo Documento Devedor: 1 - para CNPJ 2 - para CPF "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" Selecione uma das altenativas: ")
                              ]),
                              _c(VCardText, { staticClass: "mt-0 pt-0" }, [
                                _vm._v(" 1 - para CNPJ 2 - para CPF ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" CPF/CNPJ Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hifens "
                                )
                              ]),
                              _c(VCardText, [
                                _vm._v(
                                  " Enviar o CNPJ ou CPF, sem formatação. Se houver zeros à esquerda, enviar. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Correto: 30980363000100 ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Incorreto: 30.980.363/0001-00 ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Nome do Sacador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: limite máximo de 45 caracteres, sem simbolos, acento ou outros caracteres especiais. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" CNPJ do Sacador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hifens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Endereço do Sacador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: limite maximo de 45 caracteres, sem simbolos, acento ou outros caracteres especiais "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Cidade do Sacador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hifens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Cep do Sacador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hifens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" UF do Sacador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: retire pontos, simbolos e hifens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Nome Credor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: limite maximo de 45 caracteres, sem simbolos, acento ou outros caracteres especiais. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Numero do Título ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: esse número é aquele criado/utilizado pela empresa para seu controle interno. Retire pontos, simbolos e hifens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" Número do Título no sistema legado ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Número da Parcela ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  ' "1" se todo o contrato ou primeira parcela vencida para ser protestada OU "..." para a parcela vencida a ser protestada. '
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "green ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("done")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Correto: 1 ")
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mt-0 pt-0" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "error ma-2 pa-2",
                                      attrs: { small: "", icon: "" }
                                    },
                                    [
                                      _c(
                                        VIcon,
                                        { staticClass: "white--text" },
                                        [_vm._v("close")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v("Uso Incorreto: Parcela 1 ")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4 grey--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Nosso Número ")]
                              ),
                              _c(VCardText, { staticClass: "grey--text" }, [
                                _vm._v(
                                  " Atualização: Esse campo foi removido do arquivo de upload. Dica: deixe em branco, após o envio a Plataforma criará o número "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4 grey--text",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "mx-0grey--text" },
                                [
                                  _vm._v(
                                    " Nosso Número de identificação para cobrança "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4 grey--text",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "grey--text" }, [
                                _vm._v(" Campo Obrigatório ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Espécie ")]
                              ),
                              _c(VCardText, [
                                _vm._v(" Campo deve ser preenchido ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Dica: principais títulos são Duplicata Mercantil por Indicacão (DMI) e Cédulas Bancárias (CBI) "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Aceite ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Campo deve ser preenchido de acordo com a espécie "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Espécie: DMI/DSI Campo aceite: N ---- Espécie: CBI Campo Aceite: em branco ---- Espécie: DM/DS Campo aceite: A ---- "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Não é obrigatório, pode ser preenchido acordo com a espécie, ou ficar em branco"
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Declaração ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: Para CBI informar: C Para DMI ou DSI informar: S "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Para CBI informar: C Para DMI ou DSI informar: S "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Endosso ")]
                              ),
                              _c(VCardText, [
                                _vm._v(" Dica: deixar o campo vazio ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Informações providas pelo Jurídico/RECUPERI na homologação do cliente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Valor do Título ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: valor original do título ou parcela "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" Valor original do título ou parcela ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Valor Saldo (Protesto) ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: valor a ser efetivamente protestado (pode ser maior ou menor que o valor original do título - por exemplo: o valor protestado pode ter acréscimo de juros, multa, custos ou mesmo descontos) "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Valor a ser protestado, já com juros ou abatimentos "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Data Emissão do Título ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: data em que o título foi emitido originalmente "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Data de Emissão do Título. Formato ISO YYYY-MM-DD "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Data Vencimento da Parcela ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: data em que o pagamente deveria ter sido realizado - formato ISO YYYY-MM-DD "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Data de Vencimento (já deve estar vencido) . Formato ISO YYYY-MM-DD "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Campo Obrigatório ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" E-mail do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: Retire pontos, simbolos e hifens. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" email@exemplo.com.br ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Opcional. Não é enviado para protestado, mas é utilizado como resultado de busca "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Telefone do Devedor ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: Retire pontos, simbolos e hifens. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" (DDD)99999-0000 ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Opcional. Não é enviado para o protesto, mas serve como chave de busca "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Carteira ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: Retire pontos, simbolos e hifens "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" (Número da Carteira) ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Opcional. Não é enviado para o protesto, mas serve como chave de busca "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Notificação por email ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: Especifica se o cliente receberá notificações do sistema de Mensageria da plataforma Recuperi, caso o email dele tenha sido informado. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(
                                  " Preencher 1 ou S para confirmar o envio. Deixar em branco caso não deseje utilizar as notificações. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [_vm._v(" Opcional. ")])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "amber white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Agrupador ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Dica: É preciso cadastrar o Agrupador previamente na plataforma para utilizá-lo. Caso seja informado um código inválido/inexistente, o Título será consolidado na plataforma sem o dado de Agrupador. É possível alterar esse dado posteriormente. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" A01 ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Opcional. Não é enviado para o protesto, mas serve para setorizar a visibilidade dos Títulos "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "cyan darken-1 white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Falimentar ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Informar se o título será protestado para fins falimentares. Preencher com a letra F caso seja, caso contrario, deixar em branco. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" F ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Opcional. Preenchimentos diferentes de F serão considerados como em branco "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                VCard,
                { staticClass: "ma-3 pa-3" },
                [
                  _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { md: "4" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "success white--text",
                                    width: "100%"
                                  }
                                },
                                [_vm._v(" Quantidade de devedores ")]
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Caso tenha mais de um devedor, as informações que estão nesta planilha vão ser consideradas como informações do devedor principal; os outros devedores precisam ser adicionados pela plataforma e somente depois do preenchimento dos outros devedores o título será enviado a protesto. "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "5" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Exemplo "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("format_list_bulleted")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, { staticClass: "mx-0" }, [
                                _vm._v(" 5 ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: {
                                    dense: "",
                                    color: "grey lighten-4",
                                    width: "100%"
                                  }
                                },
                                [
                                  _vm._v(" Observações "),
                                  _c(VIcon, { staticClass: "ml-2" }, [
                                    _vm._v("warning")
                                  ])
                                ],
                                1
                              ),
                              _c(VCardText, [
                                _vm._v(
                                  " Opcional. Preencha com a quantidade de devedores que o título possui, podendo ser de 1 a 8. Caso este campo não seja preenchido, ele será setado como 1 "
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }