<template>
    <div id="margin-dialog-body">
        <v-container>
            <v-card>
                <v-snackbar
                    :timeout="-1"
                    :value="true"
                    v-model="snackbar"
                    color="primary"
                    center
                    bottom
                >
                    <v-card-text class="font-weight-black">
                        Deseja fazer o download do template agora?
                        <v-btn text small :href="link_modelo">
                            Clique aqui
                        </v-btn>
                        <v-btn
                            x-small
                            class="white black--text mx-3"
                            fab
                            @click="fechaSnack"
                            ><v-icon>close</v-icon></v-btn
                        >
                    </v-card-text>
                </v-snackbar>
            </v-card>
            <v-card class="pa-3">
                <v-row>
                    <v-col xs="2" sm="2" md="2" lg="2" cols="12"
                        ><v-icon size="100" class="grey--text mt-5 pt-5"
                            >mdi-file-upload</v-icon
                        ></v-col
                    >
                    <v-col xs="4" sm="4" md="5" lg="5" cols="12">
                        <v-card-text
                            class="
                                grey--text
                                display-1
                                font-weight-black
                                text-wrap
                            "
                        >
                            Manual de Upload via Painel</v-card-text
                        >
                        <v-card-text class="font-weight-light text-wrap">
                            A funcionalidade upload de títulos permite o envio
                            de títulos para protesto para cartórios de protesto
                            em todos os Estados do Brasil. Ela é disponibilizada
                            logo após a homologação de um novo Apresentante
                            perante os cartórios de protesto e envio das
                            informações de acesso. Para tanto, o usuário faz a
                            importação de planilha com os dados dos títulos
                            completando o respectivo template padrão, cumprindo
                            nossas instruções para realizar o seu upload na
                            www.recuperi.com.br.
                        </v-card-text></v-col
                    >
                    <v-col xs="4" sm="4" md="5" lg="5" cols="12">
                        <v-img
                            class="margin-auto ma-3 pa-3"
                            src="@/assets/planilha-upload.png"
                        ></v-img>
                    </v-col>
                </v-row>
            </v-card>
            <v-col md="12">
                <v-card-text
                    class="grey--text font-weight-black title text-wrap"
                    >Legenda de Cores da planilha:</v-card-text
                >
                <v-card-text class="font-weight-light"
                    >Cada campo na planilha possui seu tipo de informação
                    definido atraves das cores abaixo:</v-card-text
                >
                <v-row class="ma-3 pa-3">
                    <v-col md="4">
                        <v-card class="grey lighten-4">
                            <v-row>
                                <v-btn fab color="success"
                                    ><v-icon class="white--text"
                                        >account_circle</v-icon
                                    ></v-btn
                                >
                                <v-card-title class="grey--text title"
                                    >Dados do Devedor</v-card-title
                                >
                            </v-row>
                            <v-card-text class="font-weight-light"
                                >Dados referente ao Devedor, como Nome, Cidade,
                                Endereço, etc.</v-card-text
                            >
                            <v-card-text class="font-weight-light"
                                >Confira abaixo todos os campos listados e suas
                                validações</v-card-text
                            >
                        </v-card>
                    </v-col>
                    <v-col md="4">
                        <v-card class="grey lighten-4">
                            <v-row>
                                <v-btn fab color="amber"
                                    ><v-icon class="white--text"
                                        >account_circle</v-icon
                                    ></v-btn
                                >
                                <v-card-title class="grey--text title"
                                    >Dados do Sacador</v-card-title
                                >
                            </v-row>
                            <v-card-text class="font-weight-light"
                                >Dados referentes ao Sacador são gerados
                                automaticamente pelo sistema</v-card-text
                            >
                            <v-card-text class="font-weight-light"
                                >Confira abaixo todos os campos listados e suas
                                validações</v-card-text
                            >
                        </v-card>
                    </v-col>
                    <v-col md="4">
                        <v-card class="grey lighten-4">
                            <v-row>
                                <v-btn fab color="cyan darken-1"
                                    ><v-icon class="white--text"
                                        >account_circle</v-icon
                                    ></v-btn
                                >
                                <v-card-title class="grey--text title"
                                    >Parâmetros de Protesto</v-card-title
                                >
                            </v-row>
                            <v-card-text class="font-weight-light"
                                >Dados de parâmetros são informações utilizadas
                                pelo sistema para emitir o protesto</v-card-text
                            >
                            <v-card-text class="font-weight-light"
                                >Confira abaixo todos os campos listados e suas
                                validações</v-card-text
                            >
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col sm="12" md="12">
                <v-card-text class="grey--text font-weight-black title"
                    >Informações básicas de cada campo da planilha</v-card-text
                >
                <v-card-text class="font-weight-light">
                    Para facilitar o processo de upload, preparamos esse manual
                    onde estão listados todos os campos do template:
                </v-card-text>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Nome do Devedor
                                </v-btn>
                                <v-card-text>
                                    Informe aqui o Nome do Devedor por extenso.
                                    Dica: limite máximo de 45 caracteres, sem
                                    símbolos, acento ou outros caracteres
                                    especiais
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >
                                    Correto: Francisco Araujo de Cardoso
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: Francisco A. de Cardoso.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório. </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Endereço do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: limite máximo de 45 caracteres, sem
                                    símbolos, acento ou outros caracteres
                                    especiais
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: Avenida Silva Jardim, 450, ap
                                    100 *(se houver apartamento)
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: Avenida Sete de Setembro, 2000
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: Avenida Silva Jardim, 450,
                                    Curitiba, Cep 81100-200
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                                <v-card-text>
                                    Observação: As informações de Bairro, CEP, e
                                    Cidade não podem ser informadas nesse campo.
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Bairro do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: limite máximo de 20 caracteres, sem
                                    símbolos, acento ou outros caracteres
                                    especiais
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: Centro
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: Centro, Curitiba, Cep
                                    81100-200
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    CEP do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hífens
                                </v-card-text>
                                <v-card-text>
                                    Informar o CEP com 8 dígitos. Se tiver zeros
                                    à esquerda, é preciso informar. Exemplo:
                                    01418001
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: 81110-200
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: 81110200
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: 81110.200
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Cidade do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hífens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: Curitiba
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: Curitiba-PR
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    UF do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hífens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: PR
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: Paraná
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Tipo Documento Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: Especifica qual o tipo de documento a
                                    ser enviado no campo Tipo Documento Devedor:
                                    1 - para CNPJ 2 - para CPF
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Selecione uma das altenativas:
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    1 - para CNPJ 2 - para CPF
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    CPF/CNPJ Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hifens
                                </v-card-text>
                                <v-card-text>
                                    Enviar o CNPJ ou CPF, sem formatação. Se
                                    houver zeros à esquerda, enviar.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: 30980363000100
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: 30.980.363/0001-00
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Nome do Sacador
                                </v-btn>
                                <v-card-text>
                                    Dica: limite máximo de 45 caracteres, sem
                                    simbolos, acento ou outros caracteres
                                    especiais.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    CNPJ do Sacador
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hifens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Endereço do Sacador
                                </v-btn>
                                <v-card-text>
                                    Dica: limite maximo de 45 caracteres, sem
                                    simbolos, acento ou outros caracteres
                                    especiais
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Cidade do Sacador
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hifens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Cep do Sacador
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hifens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    UF do Sacador
                                </v-btn>
                                <v-card-text>
                                    Dica: retire pontos, simbolos e hifens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Nome Credor
                                </v-btn>
                                <v-card-text>
                                    Dica: limite maximo de 45 caracteres, sem
                                    simbolos, acento ou outros caracteres
                                    especiais.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Numero do Título
                                </v-btn>
                                <v-card-text>
                                    Dica: esse número é aquele criado/utilizado
                                    pela empresa para seu controle interno.
                                    Retire pontos, simbolos e hifens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Número do Título no sistema legado
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Número da Parcela
                                </v-btn>
                                <v-card-text>
                                    "1" se todo o contrato ou primeira parcela
                                    vencida para ser protestada OU "..." para a
                                    parcela vencida a ser protestada.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    <v-btn small icon class="green ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >done</v-icon
                                        ></v-btn
                                    >Uso Correto: 1
                                </v-card-text>
                                <v-card-text class="mt-0 pt-0">
                                    <v-btn small icon class="error ma-2 pa-2"
                                        ><v-icon class="white--text"
                                            >close</v-icon
                                        ></v-btn
                                    >Uso Incorreto: Parcela 1
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4 grey--text"
                                    width="100%"
                                >
                                    Nosso Número
                                </v-btn>
                                <v-card-text class="grey--text">
                                    Atualização: Esse campo foi removido do
                                    arquivo de upload. Dica: deixe em branco,
                                    após o envio a Plataforma criará o número
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4 grey--text"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0grey--text">
                                    Nosso Número de identificação para cobrança
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4 grey--text"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text class="grey--text">
                                    Campo Obrigatório
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Espécie
                                </v-btn>
                                <v-card-text>
                                    Campo deve ser preenchido
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Dica: principais títulos são Duplicata
                                    Mercantil por Indicacão (DMI) e Cédulas
                                    Bancárias (CBI)
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Aceite
                                </v-btn>
                                <v-card-text>
                                    Campo deve ser preenchido de acordo com a
                                    espécie
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Espécie: DMI/DSI Campo aceite: N ----
                                    Espécie: CBI Campo Aceite: em branco ----
                                    Espécie: DM/DS Campo aceite: A ----
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Não é obrigatório, pode ser preenchido
                                    acordo com a espécie, ou ficar em
                                    branco</v-card-text
                                >
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Declaração
                                </v-btn>
                                <v-card-text>
                                    Dica: Para CBI informar: C Para DMI ou DSI
                                    informar: S
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Para CBI informar: C Para DMI ou DSI
                                    informar: S
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Endosso
                                </v-btn>
                                <v-card-text>
                                    Dica: deixar o campo vazio
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Informações providas pelo Jurídico/RECUPERI
                                    na homologação do cliente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Valor do Título
                                </v-btn>
                                <v-card-text>
                                    Dica: valor original do título ou parcela
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Valor original do título ou parcela
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Valor Saldo (Protesto)
                                </v-btn>
                                <v-card-text>
                                    Dica: valor a ser efetivamente protestado
                                    (pode ser maior ou menor que o valor
                                    original do título - por exemplo: o valor
                                    protestado pode ter acréscimo de juros,
                                    multa, custos ou mesmo descontos)
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Valor a ser protestado, já com juros ou
                                    abatimentos
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Data Emissão do Título
                                </v-btn>
                                <v-card-text>
                                    Dica: data em que o título foi emitido
                                    originalmente
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Data de Emissão do Título. Formato ISO
                                    YYYY-MM-DD
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Data Vencimento da Parcela
                                </v-btn>
                                <v-card-text>
                                    Dica: data em que o pagamente deveria ter
                                    sido realizado - formato ISO YYYY-MM-DD
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Data de Vencimento (já deve estar vencido) .
                                    Formato ISO YYYY-MM-DD
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text> Campo Obrigatório </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    E-mail do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: Retire pontos, simbolos e hifens.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    email@exemplo.com.br
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional. Não é enviado para protestado, mas
                                    é utilizado como resultado de busca
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Telefone do Devedor
                                </v-btn>
                                <v-card-text>
                                    Dica: Retire pontos, simbolos e hifens.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    (DDD)99999-0000
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional. Não é enviado para o protesto, mas
                                    serve como chave de busca
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Carteira
                                </v-btn>
                                <v-card-text>
                                    Dica: Retire pontos, simbolos e hifens
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    (Número da Carteira)
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional. Não é enviado para o protesto, mas
                                    serve como chave de busca
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Notificação por email
                                </v-btn>
                                <v-card-text>
                                    Dica: Especifica se o cliente receberá
                                    notificações do sistema de Mensageria da
                                    plataforma Recuperi, caso o email dele tenha
                                    sido informado.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    Preencher 1 ou S para confirmar o envio.
                                    Deixar em branco caso não deseje utilizar as
                                    notificações.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional.
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="amber white--text"
                                    width="100%"
                                >
                                    Agrupador
                                </v-btn>
                                <v-card-text>
                                    Dica: É preciso cadastrar o Agrupador
                                    previamente na plataforma para utilizá-lo.
                                    Caso seja informado um código
                                    inválido/inexistente, o Título será
                                    consolidado na plataforma sem o dado de
                                    Agrupador. É possível alterar esse dado
                                    posteriormente.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    A01
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional. Não é enviado para o protesto, mas
                                    serve para setorizar a visibilidade dos
                                    Títulos
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="cyan darken-1 white--text"
                                    width="100%"
                                >
                                    Falimentar
                                </v-btn>
                                <v-card-text>
                                    Informar se o título será protestado para fins falimentares. Preencher com a letra F caso seja, caso contrario, deixar em branco. 
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    F
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional. Preenchimentos diferentes de F serão considerados como em branco
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
                <v-card class="ma-3 pa-3">
                    <v-row>
                        <v-col md="4">
                            <v-card>
                                <v-btn
                                    dense
                                    color="success white--text"
                                    width="100%"
                                >
                                    Quantidade de devedores
                                </v-btn>
                                <v-card-text>
                                    Caso tenha mais de um devedor, as informações que estão nesta planilha vão ser consideradas como informações do devedor principal; 
                                    os outros devedores precisam ser adicionados pela plataforma e somente depois do preenchimento dos outros devedores o título será enviado a protesto.
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="5">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Exemplo
                                    <v-icon class="ml-2"
                                        >format_list_bulleted</v-icon
                                    >
                                </v-btn>
                                <v-card-text class="mx-0">
                                    5
                                </v-card-text>
                            </v-card>
                        </v-col>
                        <v-col md="3">
                            <v-card>
                                <v-btn
                                    dense
                                    color="grey lighten-4"
                                    width="100%"
                                >
                                    Observações
                                    <v-icon class="ml-2">warning</v-icon>
                                </v-btn>
                                <v-card-text>
                                    Opcional. Preencha com a quantidade de devedores que o título possui, podendo ser de 1 a 8.
                                    Caso este campo não seja preenchido, ele será setado como 1
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'DicasUpload',
    props: {
        dialogManual: {
            type: Boolean,
            required: false
        }
    },
    data: () => ({
        snackbar: true,
        link_modelo: '/RECUPERI-Planilha_Protesto2024_v4.xlsx'
    }),
    methods: {
        fechaSnack() {
            this.snackbar = false;
        },
        keyupListener(e) {
            if (!e) e = window.event;
            const keyCode = e.code || e.key;

            if (keyCode === 'Escape') {
                if (this.dialogManual) {
                    this.$emit('fecharDialogManual');
                } else {
                    this.$emit('fecharModal');
                }
            }
        }
    },
    beforeDestroy() {
        document.removeEventListener('keyup', this.keyupListener);
    },
    mounted() {
        document.addEventListener('keyup', this.keyupListener);
    }
};
</script>
